
$(document).on('turbolinks:load', function() {


  $('#appt-cancel-link').on('click', function(e) {
    e.preventDefault();
    $('#appt-cancel-link').hide();
    $('#appt-cancel-confirm-links').show();
  });

  $('#appt-cancel-confirm-no').on('click', function(e) {
    e.preventDefault();
    $('#appt-cancel-confirm-links').hide();
    $('#appt-cancel-link').show();
  });

  $('#appt-cancel-and-future-link').on('click', function(e) {
    e.preventDefault();
    $('#appt-cancel-and-future-link').hide();
    $('#appt-cancel-and-future-confirm-links').show();
  });

  $('#appt-cancel-and-future-confirm-no').on('click', function(e) {
    e.preventDefault();
    $('#appt-cancel-and-future-confirm-links').hide();
    $('#appt-cancel-and-future-link').show();
  });




// NOT WORKING...THE FOLLOWING SECTIONS are for tagging one recurring field as required if the other is selected

  $('#save-appointment').on('click', function(e) {
    // e.preventDefault();

  });


  $('#appointment_recurring, #appointment_recurring_count').on('change', function() {

    console.log('in select for BOTH and one was changed...');

    // $('#recurring-label, #recurring-count-label')

    var $recurringSelect = $('#appointment_recurring');
    var recurringSelectVal = $recurringSelect.val();

    var $recurringCountSelect = $('#appointment_recurring_count');
    var recurringCountSelectVal = $recurringCountSelect.val();

  // MAYBE:
  // to make it nice, should I hide the number of recurrences field unless a recurring frequency is selected?

    if ((recurringSelectVal !== 'no') || (recurringCountSelectVal > 0)) {

      console.log('in select for BOTH, ONE OR OTHER value > 0');


      if ((recurringSelectVal !== 'no') && (recurringCountSelectVal > 0)) {

        console.log('in select for BOTH, BOTH values > 0');

        // clear both messages

      } else {

        // add both messages

        console.log('in select for BOTH, ONLY ONE value > 0');

  // $( "<p>Test</p>" ).insertAfter( ".inner" );

        $('.recurring-appointment-select').parent().append('<div class="invalid-feedback">required for recurring appointments</div>');

  // <div class="invalid-feedback">Name can't be blank</div>
      }

    } else {
      // clear both messages
    }

  });

  $('#appointment_recurring').on('change', function() {
    var $recurringSelect = $(this);

    // console.log("$recurringSelect;", $recurringSelect);

    var recurringSelectVal = $recurringSelect.val();

    console.log("recurringSelectVal;", recurringSelectVal);

    var $recurringCountSelect = $('#appointment_recurring_count')

    var recurringCountSelectVal = $recurringCountSelect.val();

    console.log("recurringCountSelectVal;", recurringCountSelectVal);

  });

  // try:
  // if one is blank and one is selected, put "required for recurring appointments" on both fields
  // if both are blank or both are selected, have nothing

  $('#appointment_recurring_count').on('change', function() {
    var $recurringCountSelect = $(this);

    // console.log("$recurringCountSelect;", $recurringCountSelect);

    var recurringCountSelectVal = $recurringCountSelect.val();

    console.log("recurringCountSelectVal;", recurringCountSelectVal);

    var $recurringSelect = $('#appointment_recurring');

    var recurringSelectVal = $recurringSelect.val();

    console.log("recurringSelectVal;", recurringSelectVal);

  });



});
